<template>
    <div class="card">
        <div class="bg-blue-light p-2">
            <TitleButton
                :showBtn="false"
                btnTitle="Add New"
                title="Project Budget Balance Summary"
            />
        </div>

        <div class="mt-2 px-2">
            <ProjectBudgetBalanceTable :tableData="projectBudgetBalance"/>
        </div>
        <GlobalLoader />
    </div>
</template>

<script setup>
import {onMounted} from 'vue';
import TitleButton from '@/components/atom/TitleButton'
import useReport from '@/services/modules/dashboard/report.js'
import ProjectBudgetBalanceTable from '@/components/molecule/company/report/ProjectBudgetBalanceSummaryTable.vue'
import {useRoute} from "vue-router";
const route = useRoute()
const {fetchProjectBudgetBalance, projectBudgetBalance} = useReport()
onMounted(() => {
  fetchProjectBudgetBalance(getQuery())
})

function getQuery () {
  return '?company_id=' + route.params.companyId
}
</script>
